import * as React from "react";
// import logo from '../../assets/icon.png';
import logo from '../../assets/logo_default.png';
import {Link} from "gatsby"

const Header = () => (
    <header className="text-gray-600 body-font">
        <div className="flex items-center justify-center">
            <Link to="/">
                <img className="h-20 w-auto sm:h-24 sm:w-auto" src={logo} alt="NK"/>
                {/*<img src={logo} className="w-24 h-auto p-1 m-0" alt="NK"/>*/}
            </Link>
        </div>
    </header>
)

export default Header;
